@use '../base/mixin';

.intro__summary{
  max-width: 1080px;
  > * + * {
    margin-top: mixin.calc-em(38.4px);
  }
  p, h1{
    // font-size: mixin.calc-em(24px);
    // line-height: mixin.calc-em(38.4px);
    font-size: clamp(1.5rem, calc(2.857vw + 0.786rem), 2.5rem);
    line-height: clamp(2.4rem, calc(4.571vw + 1.257rem), 4rem);
    letter-spacing: -0.02em;
    max-width: mixin.calc-em(1080px);
    font-weight: 400;
  }
  // @include mixin.breakpoint(medium){
  //   p{
  //     font-size: mixin.calc-em(40px);
  //     line-height: mixin.calc-em(64px);
  //   }
  // }
}