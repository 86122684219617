@use '../base/theme';
@use '../base/mixin';


.site-header{
  &__inner{
    display: flex;
    padding: mixin.calc-em(24px) 0 mixin.calc-em(24px) 0;
    flex-direction: column;
    margin-bottom: calc-em(48px);
    @include mixin.breakpoint(small){
      padding: mixin.calc-em(48px) 0 mixin.calc-em(48px) 0;
    }
    @include mixin.breakpoint(medium){
      padding: mixin.calc-em(64px) 0  mixin.calc-em(64px) 0;
      margin-bottom: calc-em(96px);
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.nav__list{
  display: flex;
  column-gap: mixin.calc-em(16px);
  flex-wrap: wrap;
  @include mixin.breakpoint(medium){
    column-gap: mixin.calc-em(24px);
  }
  li{
    display: flex;
    align-items: end;
    line-height: 0;
    height: 48px;
    align-items: center;
    // margin-left: 1rem;
    @include mixin.breakpoint(small){
      align-items: flex-end;
    }
  }
  a{
    display: inline-block;
    text-decoration: none;
    font-size: mixin.calc-em(18px);
    line-height: mixin.calc-em(18px);
    font-weight: 700;
    color: theme.$grey15;
    position: relative;
    top: 5px;
    @include mixin.slideHover(theme.$orange2, theme.$orange);
    &.dribbble{
      @include mixin.slideHover(theme.$dribbbleBright, theme.$dribbble);
    }
    &.twitter{
      @include mixin.slideHover(theme.$twitterBright, theme.$twitter);
    }
    &.linkedin{
      @include mixin.slideHover(theme.$linkedinBright, theme.$linkedin);
    }

    @include mixin.breakpoint(small){
      font-size: mixin.calc-em(22px);
      line-height: mixin.calc-em(22px);
    }
    @include mixin.breakpoint(medium){
      font-size: mixin.calc-em(24px);
      line-height: mixin.calc-em(24px);
    }
  }
}

.logo{
  line-height: 0;
  display: inline-block;
  margin-bottom: mixin.calc-em(16px);
  svg{
    width: 110px;
    height: 48px;
  }
  @include mixin.breakpoint(small){
    margin-bottom: mixin.calc-em(0px);
  }
  @include mixin.breakpoint(medium){
    svg{
      width: 147px;
      height: 64px;
    }
  }
}