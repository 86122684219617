@use 'theme';

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Calculate ems from pixels
@function calc-em($px, $base: 16) {
  $px: strip-unit($px);
  $base: strip-unit($base);
  @if $px == 1 {
    @return 0.0725rem;
  }
  @return ($px / $base) * 1rem;
}

// Media Queries
@mixin breakpoint($point) {
  @if $point == large {
    @media screen and (min-width: theme.$bpLarge) { @content ; }
  }
  @else if $point == medium {
    @media screen and (min-width: theme.$bpMedium) { @content ; }
  }
  @else if $point == small {
    @media screen and (min-width: theme.$bpSmall) { @content ; }
  }
}

// gradient slide in text hover
@mixin slideHover($colorStart, $colorEnd) {
  background: linear-gradient(to right, $colorStart, $colorEnd 50%, theme.$grey15 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  &:hover{
    color: $colorEnd;
    background-position: 0 100%;
  }
}