// Palette
$grey15: #212529;
$blue: #0047FC;
$blueLight: #109AFE;
$orange: #FF3D00;
$orange2: #FF4C13;
$orange3: #FFC078;
$orange1: #FFF4E6;

// social colors
$twitter: #1DA1F2;
$twitterBright: #1DBFF2;
$dribbble: #EA4C89;
$dribbbleBright: #FF0666;
$linkedin: #0077B5;
$linkedinBright: #009FF1; 

// Font stacks
$fontPrimary: 'Inter','Helvetica Neue',Helvetica,Arial,sans-serif;

// Breakpoints
$bpSmall: 30rem; // 480px
$bpMedium: 45rem; // 720px
$bpLarge: 60rem; // 960px