@use '../base/theme';
@use '../base/mixin';

.gallery{
    display: grid;
    grid-gap: mixin.calc-em(16px);
    @include mixin.breakpoint(medium){
        grid-gap: mixin.calc-em(32px);
        grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: repeat(4, 1fr);
        &-item{
            &:nth-of-type(1){
                grid-row: auto / span 2;
            }
            &:nth-of-type(4){
                grid-column: auto / span 2;
                grid-row: auto / span 2;
            }
            &:nth-of-type(6){
                grid-row: auto / span 2;
            }
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}