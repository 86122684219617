@use '../base/mixin';

.logos{
   margin-top: auto;
//    margin-bottom: mixin.calc-em(24px);
   max-width: mixin.calc-em(1080px);
   padding-top: mixin.calc-em(64px);
   h4{
       font-weight: 400;
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        gap: mixin.calc-em(32px);
        margin-top: mixin.calc-em(24px);
        li{
            width: 100%;
            max-width: mixin.calc-em(64px);
            img{
                width: 100%;
            }
        }
    }
    
    @include mixin.breakpoint(small){
        padding-top: mixin.calc-em(96px);
    }
    
    
    @include mixin.breakpoint(medium){
        padding-top: mixin.calc-em(128px);
        &__list{
            gap: mixin.calc-em(40px);
        }
        li{
            max-width: mixin.calc-em(72px);
        }
    }
}