@use '../base/theme';
@use '../base/mixin';


.page-intro{
    margin-bottom:mixin.calc-em(48px);
    @include mixin.breakpoint(medium){
        margin-bottom:mixin.calc-em(108px);
    }

    &__summary{
        margin-top: mixin.calc-em(16px);
        @include mixin.breakpoint(medium){
            margin-top:mixin.calc-em(32px);
        }
    }
}
.page-title{
    overflow-wrap: break-word;
    hyphens: auto;
    font-size: clamp(1em, calc(.5em + 1vw), 2em);
    font-size: clamp(2.5rem, calc(13.75vw + -0.25rem), 8rem);
    line-height: clamp(2.5rem, calc(13.75vw + -0.25rem), 8rem);
    // line-height: mixin.calc-em(76px);
    margin-top: mixin.calc-em(8px);
    @include mixin.breakpoint(medium){
        // line-height: mixin.calc-em(152px);
        margin-top: mixin.calc-em(16px);
    }
}
