@use "../base/mixin";

.site-footer{
  padding-bottom: mixin.calc-em(24px);
  @include mixin.breakpoint(small){
    padding-bottom: mixin.calc-em(48px);
  }
  @include mixin.breakpoint(medium){
    padding-bottom: mixin.calc-em(64px);
  }

  &__inner{
    @include mixin.breakpoint(large){
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

.copyright{
  font-size: mixin.calc-em(12px);
  padding-top: mixin.calc-em(16px);
  order: -1;
  @include mixin.breakpoint(large){
    font-size: mixin.calc-em(16px);
    padding-top: 0;
    
  }
}